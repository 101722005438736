import React, { useContext, useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import {  Checkbox, FormControlLabel } from "@material-ui/core";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import IconButton from "@material-ui/core/IconButton";
import { i18n } from "../../translate/i18n";
import { head } from "lodash";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import MessageVariablesPicker from "../MessageVariablesPicker";
import Typography from "@material-ui/core/Typography";
import ButtonWithSpinner from "../ButtonWithSpinner";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { FieldArray, ErrorMessage } from "formik";



import {
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
} from "@material-ui/core";
import ConfirmationModal from "../ConfirmationModal";

const path = require('path');

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    multFieldLine: {
        display: "flex",
        "& > *:not(:last-child)": {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    colorAdorment: {
        width: 20,
        height: 20,
    },
}));

const QuickeMessageSchema = Yup.object().shape({
    descricao: Yup.string().required("Obrigatório"),
    //   message: Yup.string().required("Obrigatório"),
});
const filter = createFilterOptions({
    trim: true,
  });

const QuickMessageDialog = ({ open, onClose, reload, modalOpen,  initialContact,  values, touched, errors, isSubmitting, setFieldValue, quickemessageId }) => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { profile } = user;
    const messageInputRef = useRef();
  
   
  
    const [loading, setLoading] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [selectedQueue, setSelectedQueue] = useState("");
    const [selectedWhatsapp, setSelectedWhatsapp] = useState("");
    const [newContact, setNewContact] = useState({});
    const [whatsapps, setWhatsapps] = useState([]);
   
    const { companyId, whatsappId } = user;
    const [searchValue, setSearchValue] = useState("");


 

    const calculateTotal = (OrcamentoItens, discount = 0) => {
      const total = (Array.isArray(OrcamentoItens) ? OrcamentoItens : []).reduce((acc, item) => {
        const rawPrice = item.valorProduto != null ? String(item.valorProduto) : "0";
        
        // Remover todos os caracteres não numéricos exceto ponto ou vírgula
        const cleanPrice = rawPrice.replace('R$ ', '');
        // Converter para float
        const price = parseFloat(cleanPrice) || 0;
        
        const quantity = parseInt(item.quantidadeProduto) || 0;
    
        return acc + price * quantity;
      }, 0);
    
      // Aplicar desconto em porcentagem
      const discountValue = (total * (parseFloat(discount) || 0)) / 100;
      const totalWithDiscount = total - discountValue;
    
      settotalValor(totalWithDiscount);
      return totalWithDiscount;
    };
    
      
      
      

      
      
      
      
      
    
    const formik = Formik({
        initialValues: {
          products: [] // Inicializa como um array vazio
        },
        // outras configurações
      });

    const initialState = {
        descricao: "",
        //geral: false,
        status: 'pendente',
    };

    const [confirmationOpen, setConfirmationOpen] = useState(false);
    const [quickemessage, setQuickemessage] = useState(initialState);
    const [attachment, setAttachment] = useState(null);
    const attachmentFile = useRef(null);
    const [options, setOptions] = useState([]);
    const [selectedContact, setSelectedContact] = useState(null);
    const [totalValor, settotalValor] = useState(null);
    

    useEffect(() => {
        try {
            (async () => {
                if (!quickemessageId) return;

                const { data } = await api.get(`/orcamentos/${quickemessageId}`);
                console.log(data);

                setQuickemessage((prevState) => {
                    return { ...prevState, ...data };
                });
            })();
        } catch (err) {
            toastError(err);
        }
    }, [quickemessageId, open]);



    useEffect(() => {
        if (initialContact?.id !== undefined) {
          setOptions([initialContact]);
          setSelectedContact(initialContact);
        }
      }, [initialContact]);
    
      useEffect(() => {
       
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
          const fetchContacts = async () => {
            api
              .get(`/whatsapp`, { params: { companyId, session: 0 } })
              .then(({ data }) => setWhatsapps(data));
          };
    
          if (whatsappId !== null && whatsappId!== undefined) {
            setSelectedWhatsapp(whatsappId)
          }
    
          if (user.queues.length === 1) {
            setSelectedQueue(user.queues[0].id)
          }
          fetchContacts();
          setLoading(false);
        }, 500);
        return () => clearTimeout(delayDebounceFn);
      }, [])
    
      useEffect(() => {
        if (searchParam.length < 3) {
          setLoading(false);
          return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
          const fetchContacts = async () => {
            try {
              const { data } = await api.get("contacts", {
                params: { searchParam },
              });
              setOptions(data.contacts);
              setLoading(false);
            } catch (err) {
              setLoading(false);
              toastError(err);
            }
          };
          fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
      }, [searchParam, modalOpen]);

    const handleClose = () => {
        setQuickemessage(initialState);
        setAttachment(null);
        onClose();
    };

    const handleAttachmentFile = (e) => {
      
        const file = head(e.target.files);
        if (file) {
            setAttachment(file);
        }
    };
    

    const handleSaveQuickeMessage = async (values) => {

        const quickemessageData = { ...values, user, selectedContact, totalValor, isMedia: true, mediaPath: attachment ? String(attachment.name).replace(/ /g, "_") : values.mediaPath ? path.basename(values.mediaPath).replace(/ /g, "_") : null };

        try {
            if (quickemessageId) {
                await api.put(`/orcamentos/${quickemessageId}`, quickemessageData);
                if (attachment != null) {
                    const formData = new FormData();
                    formData.append("typeArch", "quickMessage");
                    formData.append("file", attachment);
                    await api.post(
                        `/orcamentos/${quickemessageId}/media-upload`,
                        formData
                    );
                }
            } else {
                const { data } = await api.post("/orcamentos", quickemessageData);
                if (attachment != null) {
                    const formData = new FormData();
                    formData.append("typeArch", "quickMessage");
                    formData.append("file", attachment);
                    await api.post(`/orcamentos/${data.id}/media-upload`, formData);
                }
            }
            toast.success('Orçamento adicionado com sucesso');
            if (typeof reload == "function") {

                reload();
            }
        } catch (err) {
            toastError(err);
        }
        handleClose();
    };

    const deleteMedia = async () => {
        if (attachment) {
            setAttachment(null);
            attachmentFile.current.value = null;
        }

        if (quickemessage.mediaPath) {
            await api.delete(`/quick-messages/${quickemessage.id}/media-upload`);
            setQuickemessage((prev) => ({
                ...prev,
                mediaPath: null,
            }));
            toast.success(i18n.t("quickMessages.toasts.deleted"));
            if (typeof reload == "function") {

                reload();
            }
        }
    };

    const handleSelectOption = (e, newValue) => {
        if (newValue?.number) {
          setSelectedContact(newValue);
          setSearchValue(true);
        } else if (newValue?.name) {
          setNewContact({ name: newValue.name });
          setContactModalOpen(true);
        }
      };

      const createAddContactOption = (filterOptions, params) => {
        const filtered = filter(filterOptions, params);
        if (params.inputValue !== "" && !loading && searchParam.length >= 3) {
          filtered.push({
            name: `${params.inputValue}`,
          });
        }
        return filtered;
      };

    
    
      const handleCloseContactModal = () => {
        setContactModalOpen(false);    
      };
    
      
    
  
    
      const renderOption = option => {
        if (option.number) {
          
              
          return <>
            {/* {IconChannel(option.channel)} */}
            <Typography component="span" style={{ fontSize: 14, marginLeft: "10px", display: "inline-flex", alignItems: "center", lineHeight: "2" }}>
              {option.name} - {option.number}
            </Typography>
          </>
        } else {
           
          return `Nenhum contato encontrado`;
        }
      };
    
      const renderOptionLabel = option => {
        if (option.number) {
          return `${option.name} - ${option.number}`;
        } else {
          return `${option.name}`;
        }
      };

    const renderContactAutocomplete = () => {
        if (initialContact === undefined || initialContact.id === undefined) {
          return (
            <Grid xs={12} item>
              <Autocomplete
                fullWidth
                options={options}
                loading={loading}
                clearOnBlur
                autoHighlight
                freeSolo
                clearOnEscape
                getOptionLabel={renderOptionLabel}
                renderOption={renderOption}
                filterOptions={createAddContactOption}
                onChange={(e, newValue) => handleSelectOption(e, newValue)}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={i18n.t("newTicketModal.fieldLabel")}
                    variant="outlined"                  
                    
                    required
                    onChange={e => setSearchParam(e.target.value)}
                    onKeyPress={e => {
                      if (loading || !selectedContact) return;                        
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
          )
        }
        return null;
      }

    const handleClickMsgVar = async (msgVar, setValueFunc) => {
        const el = messageInputRef.current;
        const firstHalfText = el.value.substring(0, el.selectionStart);
        const secondHalfText = el.value.substring(el.selectionEnd);
        const newCursorPos = el.selectionStart + msgVar.length;

        setValueFunc("message", `${firstHalfText}${msgVar}${secondHalfText}`);

        await new Promise(r => setTimeout(r, 100));
        messageInputRef.current.setSelectionRange(newCursorPos, newCursorPos);
    };

    return (
        <div className={classes.root}>
            <ConfirmationModal
                title={i18n.t("quickMessages.confirmationModal.deleteTitle")}
                open={confirmationOpen}
                onClose={() => setConfirmationOpen(false)}
                onConfirm={deleteMedia}
            >
                {i18n.t("quickMessages.confirmationModal.deleteMessage")}
            </ConfirmationModal>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="md"
                fullWidth
                scroll="paper"
            >
                <DialogTitle id="form-dialog-title">
                    {quickemessageId
                        ? `${i18n.t("quickMessages.dialog.edit")}`
                        : `${i18n.t("quickMessages.dialog.add")}`}
                </DialogTitle>
                <div style={{ display: "none" }}>
                    <input
                        type="file"
                        ref={attachmentFile}
                        onChange={(e) => handleAttachmentFile(e)}
                    />
                </div>
                <Formik
  initialValues={quickemessage}
  enableReinitialize={true}
  validationSchema={QuickeMessageSchema}
  onSubmit={(values, actions) => {
    if (!Array.isArray(values.OrcamentoItens) || values.OrcamentoItens.length === 0) {
      toast.error("Você deve adicionar ao menos um produto.");
      actions.setSubmitting(false);
      return;
    }

    setTimeout(() => {
      handleSaveQuickeMessage(values);
      actions.setSubmitting(false);
    }, 400);
  }}
>
  {({ touched, errors, isSubmitting, setFieldValue, values }) => (
    <Form>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Field
              as={TextField}
              autoFocus
              label="Descrição"
              name="descricao"
              error={touched.descricao && Boolean(errors.descricao)}
              helperText={touched.descricao && errors.descricao}
              variant="outlined"
              margin="dense"
              fullWidth
            />
          </Grid>

          {/* Campo de Autocomplete para Contato */}
          <Grid item xs={12}>
            {!quickemessageId ? (
              renderContactAutocomplete()
            ) : (
              <Field
                as={TextField}
                autoFocus
                label=""
                name="contact.name"
                variant="outlined"
                margin="dense"
                fullWidth
                disabled
              />
            )}
          </Grid>

          {/* Produtos (OrcamentoItens) */}
          <Grid item xs={12}>
            <FieldArray name="OrcamentoItens">
              {({ push, remove }) => (
                <>
                  {(Array.isArray(values.OrcamentoItens) ? values.OrcamentoItens : []).map(
                    (item, index) => (
                      <Grid container spacing={2} key={index} alignItems="center">
                        <Grid item xs={4}>
                          <Field
                            as={TextField}
                            label="Nome do Produto / Serviço"
                            name={`OrcamentoItens[${index}].produtoName`}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={3}>
                          <Field name={`OrcamentoItens[${index}].valorProduto`}>
                            {({ field }) => (
                              <NumberFormat
                                {...field}
                                label="Preço"
                                customInput={TextField}
                                //thousandSeparator="."
                                decimalSeparator="."
                                prefix="R$ "
                                variant="outlined"
                                fullWidth
                                required
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={3}>
                          <Field
                            as={TextField}
                            label="Quantidade"
                            name={`OrcamentoItens[${index}].quantidadeProduto`}
                            type="number"
                            inputProps={{ step: "1", min: 1 }}
                            variant="outlined"
                            fullWidth
                            required
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => remove(index)}
                          >
                            Remover
                          </Button>
                        </Grid>
                      </Grid>
                    )
                  )}
                  <Grid container justifyContent="center" style={{ marginTop: 16 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => push({ produtoName: "", valorProduto: 0, quantidadeProduto: 1 })}
                    >
                      Adicionar Produto
                    </Button>
                  </Grid>
                </>
              )}
            </FieldArray>
          </Grid>

          {/* Mostrar Total e Desconto apenas se houver produtos */}
          {Array.isArray(values.OrcamentoItens) && values.OrcamentoItens.length > 0 && (
            <>
              {/* Desconto */}
              <Grid item xs={12}>
                <Field
                  as={TextField}
                  label="Desconto (%)"
                  name="desconto"
                  type="number"
                  inputProps={{ step: "0", min: 0, max: 100 }}
                  variant="outlined"
                  margin="dense"
                  fullWidth
                />
              </Grid>

              {/* Total */}
              <Grid item xs={12}>
                <TextField
                  label="Total"
                  value={`R$ ${calculateTotal(values.OrcamentoItens, values.desconto).toFixed(2)}`}
                  variant="outlined"
                  name="valorTotal"
                  margin="dense"
                  fullWidth
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Grid>
            </>
          )}

          {/* Checkbox para Enviar Orçamento */}
          <Grid item xs={12}>
            <FormControlLabel
              control={<Field as={Checkbox} name="sendBudget" color="primary" />}
              label="Enviar orçamento para o cliente"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
          {i18n.t("quickMessages.buttons.cancel")}
        </Button>
        <Button
          type="submit"
          color="primary"
          disabled={isSubmitting}
          variant="contained"
          className={classes.btnWrapper}
        >
          {quickemessageId ? `${i18n.t("quickMessages.buttons.edit")}` : `${i18n.t("quickMessages.buttons.add")}`}
          {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Button>
      </DialogActions>
    </Form>
  )}
</Formik>


            </Dialog>
        </div>
    );
};

export default QuickMessageDialog;